import {ReactNode} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {getTheme, Theme, useGetThemeQuery} from '../api/digitalCertificateApi';
import {themeIcons} from '../assets/icons/themeIcons';
import {getWorkspaceCustomization} from '../utils/getWorkspaceCustomization';
import {useThemeId} from './useThemeId';

interface UseThemeConfig {
  theme: Theme;
  isLoading: boolean;
  logo: string | null | ReactNode;
}

export function useThemeConfig(): UseThemeConfig {
  const themeId = useThemeId();
  const theme = getTheme.useQueryState({themeId});

  useGetThemeQuery(
    {themeId},
    {
      // Load data only once on initial render.
      // When the request fails, do not retry. Workspace theme is used as a fallback.
      // This hook can be used in nested components, by skipping the query it prevents
      // infinite loading loops when the request fails.
      skip: !themeId || isNotNilOrEmpty(theme.data) || isNotNilOrEmpty(theme.error),
    }
  );

  const workspace = getWorkspaceCustomization();
  const workspaceThemeConfig = {theme: workspace.theme, logo: workspace.logo, isLoading: false};

  if (!themeId) {
    return workspaceThemeConfig;
  }

  if (theme.isLoading) {
    return {theme: workspace.theme, isLoading: true, logo: null};
  }

  const externalTheme = theme.data?.theme;

  if (externalTheme) {
    return {
      theme: {
        ...externalTheme,
        // Theme requires icons. Those are not currently part of the theme data.
        // All workspace themes have the same icons (which are React components).
        icons: themeIcons,
      },
      logo: theme.data?.logo.url,
      isLoading: false,
    };
  }

  return workspaceThemeConfig;
}
