import {AppLanguage, getDefaultLocaleByAppLanguage, LocaleProvider} from 'platform/locale';
import {StyleSheetManager} from 'styled-components';

import {ReactNode} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';

import {GlobalStyles} from '../../GlobalStyles';
import {i18n} from '../../i18n/i18n';
import {store} from '../../store';
import {ComponentsWithThemeProvider} from '../ComponentsWithThemeProvider/ComponentsWithThemeProvider';

interface MainBoundaryProps {
  children: ReactNode;
}

export function MainBoundary(props: MainBoundaryProps) {
  return (
    <Provider store={store}>
      <StyleSheetManager enableVendorPrefixes>
        <ComponentsWithThemeProvider>
          <HelmetProvider>
            <LocaleProvider
              language={i18n?.resolvedLanguage}
              config={getDefaultLocaleByAppLanguage(i18n?.resolvedLanguage as AppLanguage)}
            >
              {props.children}
            </LocaleProvider>
            <GlobalStyles />
          </HelmetProvider>
        </ComponentsWithThemeProvider>
      </StyleSheetManager>
    </Provider>
  );
}
