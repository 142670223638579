import {z} from 'zod';

import {stringToJSONSchema} from './stringToJSONSchema';

export function safeParseJSON<T>(json: string, schema: z.ZodType<T>) {
  const parsed = schema.safeParse(stringToJSONSchema.safeParse(json).data);

  if (parsed.error) {
    return undefined;
  }

  return parsed.data;
}
