import {isNotNil} from 'ramda';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {getAuditVideoAssetsById} from './getAuditVideoAssetsById';

export const composeVideoAssetsByRelatedActions = (
  relatedActions: RelatedAction[],
  auditCategories: AuditCategory[]
) => {
  const auditAssets = relatedActions.map((relatedAction) =>
    getAuditVideoAssetsById(relatedAction.id, auditCategories)
  );
  const assets = auditAssets.filter(isNotNil);

  return assets?.length > 0 ? assets : null;
};
