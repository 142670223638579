import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {AuditCategory} from '../api/digitalCertificateApi';

export function getAuditVideoAssetsById(id: string | Nullish, auditCategories: AuditCategory[]) {
  if (isNil(auditCategories) || isNil(id)) {
    return null;
  }
  const category = auditCategories.find(
    (category) => category.type === 'videoAssets' && category.paramDefinitionId === id
  );
  if (category?.type !== 'videoAssets') {
    return null;
  }
  return category;
}
