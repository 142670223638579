import {isFeatureEnabled} from 'feature-flags';
import {Box, HStack, Show, Space, Stack, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {Helmet} from 'react-helmet-async';

import {any, flatten, isNil, reject} from 'ramda';
import {isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER} from 'shared';

import {useSlideshow} from '../../components/Slideshow/hooks/useSlideshow';
import {Slideshow} from '../../components/Slideshow/Slideshow';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import {i18n} from '../../i18n/i18n';
import {Slide} from '../../types/Slide';
import {getCertificateHeader} from '../../utils/getCertificateHeader';
import {getPhotosByKeys} from '../../utils/getPhotosByKeys';
import {getVideosByKeys} from '../../utils/getVideosByKeys';
import {CertificateBadge} from './components/CertificateBadge';
import {HeroCardImage} from './components/HeroCardImage';
import {RatingCard} from './components/RatingCard';
import {getExteriorPhotos} from './utils/getExteriorPhotos';
import {getInteriorPhotos} from './utils/getInteriorPhotos';
import {getRatingCardParams} from './utils/getRatingCardParams';

export function Hero() {
  const {vehicleAudit, vehicleCatalogue} = useGetDigitalCertificateData();
  const [heroLightboxControls, {onOpenBySlide}] = useLightbox('hero');
  const isRatingEnabled = isFeatureEnabled('rating_dc');

  const exteriorPhotos = getExteriorPhotos(vehicleAudit);
  const interiorPhotos = getInteriorPhotos(vehicleAudit);
  const morePhotos = getPhotosByKeys({
    vehicleAudit,
    uniqueKey: 'MORE_PHOTOS',
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });
  const videos =
    getVideosByKeys({
      vehicleAudit,
      uniqueKey: 'VIDEOS',
      parentUniqueKey: 'PHOTODOCUMENTATION',
    }) ?? [];

  const allPhotos = flatten(reject(isNil, [exteriorPhotos, interiorPhotos, morePhotos]));
  const slides = [...allPhotos, ...videos];

  const {slideshowApi} = useSlideshow([...allPhotos, ...videos]);

  const ratingCardParams = vehicleAudit ? getRatingCardParams(vehicleAudit) : undefined;
  const certificateHeader = getCertificateHeader({
    vehicle: vehicleAudit?.vehicle,
    vehicleCatalogue,
  });

  const handleSlideClick = (slide: Slide) => {
    onOpenBySlide(slide, slides);
  };

  return (
    <section id="hero">
      <Helmet>
        <title>{certificateHeader?.makeModel}</title>
      </Helmet>
      <Lightbox
        data-testid="heroAll"
        controls={heroLightboxControls}
        images={allPhotos}
        videos={videos}
      />
      <Box width="100%" overflow="hidden" backgroundColor="fill.white">
        <HStack justify="center">
          <Box
            width="100%"
            maxWidth={400}
            paddingVertical={[2, 3, 3, 6]}
            paddingHorizontal={[2, 10, 10, 20]}
          >
            <Stack
              spacing={[3, 6, 6, 10]}
              direction={['column', 'column', 'row', 'row']}
              height={isNotNilOrEmpty(slides) ? ['auto', 'auto', 200, 200] : 'auto'}
              maxHeight={['auto', 'auto', 200, 200]}
              align="stretch"
            >
              <VStack grow={1} spacing={3} width={['100%', '100%', '70%', '70%']} height="100%">
                <CertificateBadge
                  data-testid="hero"
                  makeModel={certificateHeader?.makeModel ?? EMPTY_PLACEHOLDER}
                  specification={certificateHeader?.specification}
                />
                <Show when={isNotNilOrEmpty(slides)}>
                  <Slideshow api={slideshowApi} onInspectSlide={handleSlideClick} />
                </Show>
              </VStack>
              <Show
                when={
                  isNotNilOrEmpty(slides) &&
                  any(isTrue, [
                    isNotNilOrEmpty(exteriorPhotos),
                    isNotNilOrEmpty(interiorPhotos),
                    isNotNilOrEmpty(morePhotos),
                  ])
                }
              >
                <Stack
                  width={['100%', '100%', 70, 70]}
                  minWidth={['100%', '100%', 70, 70]}
                  maxHeight={['initial', 30, 'initial', 'initial']}
                  spacing={[3, 6, 6, 10]}
                  direction={['column', 'row', 'column', 'column']}
                >
                  <Show when={isNotNilOrEmpty(exteriorPhotos)}>
                    <Box
                      flex={1}
                      flexBasis={['initial', '33%', '33%', '33%']}
                      position="relative"
                      overflow="hidden"
                      height={[15, 'auto', 'auto', 'auto']}
                      maxHeight={[15, 'auto', 'auto', 'auto']}
                    >
                      <HeroCardImage slideImages={exteriorPhotos!} title={i18n.t('exterior')} />
                    </Box>
                  </Show>
                  <Show when={isNotNilOrEmpty(interiorPhotos)}>
                    <Box
                      flex={1}
                      flexBasis={['initial', '33%', '33%', '33%']}
                      position="relative"
                      overflow="hidden"
                      height={[15, 'auto', 'auto', 'auto']}
                      maxHeight={[15, 'auto', 'auto', 'auto']}
                    >
                      <HeroCardImage slideImages={interiorPhotos!} title={i18n.t('interior')} />
                    </Box>
                  </Show>
                  <Show when={isNotNilOrEmpty(morePhotos)}>
                    <Box
                      flex={1}
                      flexBasis={['initial', '33%', '33%', '33%']}
                      position="relative"
                      overflow="hidden"
                      height={[15, 'auto', 'auto', 'auto']}
                      maxHeight={[15, 'auto', 'auto', 'auto']}
                    >
                      <HeroCardImage slideImages={morePhotos!} title={i18n.t('others')} />
                    </Box>
                  </Show>
                </Stack>
              </Show>
            </Stack>
            <Space vertical={[3, 6, 6, 10]} />
            <Show when={isRatingEnabled}>
              {ratingCardParams && (
                <Stack
                  direction={['column', 'column', 'row', 'row', 'row']}
                  spacing={[3, 6, 6, 6]}
                  width="100%"
                >
                  <RatingCard {...ratingCardParams.exterior} />
                  <RatingCard {...ratingCardParams.interior} />
                  <RatingCard {...ratingCardParams.mechanic} />
                </Stack>
              )}
            </Show>
          </Box>
        </HStack>
      </Box>
    </section>
  );
}
