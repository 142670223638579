import {useSearchParams} from 'react-router-dom';

const THEME_ID_QUERY_KEY = 'theme';

export function useThemeId() {
  const [searchParams] = useSearchParams();
  const themeId = searchParams.get(THEME_ID_QUERY_KEY);

  return themeId;
}
