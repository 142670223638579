import {Image} from 'platform/foundation';

import {useThemeConfig} from '../../../hooks/useThemeConfig';
import {getWorkspaceFromUri} from '../../../utils/getWorkspaceFromUri';

const {workspace} = getWorkspaceFromUri();

export function Logo() {
  const {logo: applicationLogo} = useThemeConfig();

  if (typeof applicationLogo === 'string') {
    return (
      <Image
        maxHeight={20}
        height={20}
        width="auto"
        src={applicationLogo}
        alt={`${workspace} logo`}
      />
    );
  }

  return applicationLogo;
}
