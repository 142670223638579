import {Hide} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {partition} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {useSlideshow} from '../../../components/Slideshow/hooks/useSlideshow';
import {Slideshow} from '../../../components/Slideshow/Slideshow';
import {Slide} from '../../../types/Slide';

interface CarouselSlideshowProps extends TestIdProps {
  lightboxId: string;
  slides: Slide[];
  hideLightbox?: boolean;
  isFilterOfDamageDisabled?: true;
}

export function CarouselSlideshow(props: CarouselSlideshowProps) {
  const [lightboxControls, {onOpenBySlide}] = useLightbox(props.lightboxId);
  const {slideshowApi} = useSlideshow(props.slides);

  const [images, videos] = partition((slide: Slide) => slide.type === 'image', props.slides);

  return (
    <>
      <Hide when={props.hideLightbox}>
        <Lightbox
          images={images}
          videos={videos}
          controls={lightboxControls}
          data-testid={suffixTestId('carouselSlideshow', props)}
        />
      </Hide>
      <Slideshow
        api={slideshowApi}
        onInspectSlide={(slide) => onOpenBySlide(slide, props.slides)}
        data-testid={suffixTestId('carouselSlideshow', props)}
      />
    </>
  );
}
