import {any, values} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Mechanics} from '../../../types/Mechanics';

type BrakeLiningWear = NonNullable<Mechanics>['brake']['brakeLiningWear'];

export function hasBrakeLiningWearInfo(
  brakeLiningWear: NonNullable<Mechanics>['brake']['brakeLiningWear']
) {
  return any((item: BrakeLiningWear[keyof BrakeLiningWear]) => {
    const isNotEmpty = isNotNilOrEmpty(item);
    if (isNotEmpty) {
      return values(item).some(isNotNilOrEmpty);
    }
    return false;
  })(values(brakeLiningWear));
}
