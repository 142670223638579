import {Box, Grid, GridItem, Hide, Show, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {useState} from 'react';

import {dec, inc, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {AdaptiveImageDc} from '../../components/AdaptiveImageDc/AdaptiveImageDc';
import {CarouselSlideshow} from '../../components/Carousel/components/CarouselSlideshow';
import {CarouselHeader} from '../../components/CarouselHeader/CarouselHeader';
import {Preview} from '../../components/Preview/Preview';
import {Section} from '../../components/Section/Section';
import {PhotoDocumentationDataBundle} from '../../hooks/useGetDataForPhotoDocumentation';
import i18n from '../../i18n/i18n';
import {getSlidesByPage} from './utils/getSlidesByPage';

interface PhotoDocumentationProps extends Omit<PhotoDocumentationDataBundle, 'sectionConfig'> {}

export function PhotoDocumentation(props: PhotoDocumentationProps) {
  const [photoDocumentationLightboxControls, {onOpenBySlide}] = useLightbox('photoDocumentation');

  const [page, setPage] = useState(0);

  const onNext = (numberOfSlides: number) => {
    if (page === dec(numberOfSlides)) {
      setPage(0);
      return;
    }
    setPage(inc(page));
  };

  const onPrevious = (numberOfSlides: number) => {
    if (page === 0) {
      setPage(dec(numberOfSlides));
      return;
    }
    setPage(dec(page));
  };

  if (
    isNilOrEmpty(props.photoDocumentationPhotos) &&
    isNilOrEmpty(props.photoDocumentationVideos)
  ) {
    return null;
  }

  const slides = [...props.photoDocumentationPhotos, ...props.photoDocumentationVideos];
  const {currentSlides, pages, slidesPerPage} = getSlidesByPage({slides, page});

  return (
    <>
      <Lightbox
        data-testid="photoDocumentation"
        controls={photoDocumentationLightboxControls}
        images={props.photoDocumentationPhotos}
        videos={props.photoDocumentationVideos}
      />
      <Section
        id="PHOTODOCUMENTATION"
        data-testid="photoDocumentation"
        icon="photoDocumentation"
        heading={i18n.t('photosHeader')}
        content={
          <Box>
            <Hide onMobile>
              <VStack spacing={[3, 6, 6, 10]}>
                <CarouselHeader
                  data-testid="photoDocumentation"
                  title={i18n.t('photos')}
                  slides={slides}
                  activeSlide={page}
                  onNextClick={() => onNext(pages)}
                  onPreviousClick={() => onPrevious(pages)}
                  numberOfImages={slidesPerPage}
                />
                <Grid columns={[1, 2, 4, 4]} spacing={[6, 6, 6, 10]}>
                  {currentSlides.map((slide, index) => {
                    const imageUrl = slide.type === 'video' ? slide.coverImageUrl : slide.resizeUrl;
                    return (
                      <GridItem key={slide.id} span={1}>
                        <Show when={isNotNil(imageUrl)}>
                          <Preview
                            data-testid={`photo-[${index}]`}
                            onClick={() => onOpenBySlide(slide, slides)}
                          >
                            <AdaptiveImageDc
                              data-testid={`photo-[${index}]`}
                              fit="cover"
                              url={imageUrl ?? undefined}
                            />
                          </Preview>
                        </Show>
                        <Hide when={isNotNil(imageUrl)}>
                          <AdaptiveImageDc
                            fit="cover"
                            url={imageUrl ?? undefined}
                            data-testid={`photo-[${index}]`}
                          />
                        </Hide>
                      </GridItem>
                    );
                  })}
                </Grid>
              </VStack>
            </Hide>
            <Show onMobile>
              <CarouselSlideshow
                slides={slides}
                hideLightbox
                lightboxId="photoDocumentation"
                data-testid="photoDocumentation"
              />
            </Show>
          </Box>
        }
      />
    </>
  );
}
