import {getValueByDevice, Heading, HStack, Space, Text, useDevice} from 'platform/foundation';

import {divide, gte, inc} from 'ramda';

import {Nullish, TestIdProps} from 'shared';

import {Slide} from '../../types/Slide';
import {ButtonIcon} from '../ButtonIcon/ButtonIcon';

interface CarouselHeaderProps extends TestIdProps {
  onPreviousClick: VoidFunction;
  onNextClick: VoidFunction;
  title: string;
  slides: Partial<Slide>[];
  activeSlide: number;
  numberOfImages?: number | Nullish;
}

export function CarouselHeader(props: CarouselHeaderProps) {
  const device = useDevice();
  const roundedTotalSlides = divide(props.slides.length, props.numberOfImages ?? 1);
  const totalSlides = Math.ceil(roundedTotalSlides);

  return (
    <HStack spacing={4} align="center" justify="space-between">
      <ButtonIcon
        icon="arrowLeft"
        variant="secondary"
        size={getValueByDevice(device, 'small', 'default')}
        isDisabled={gte(1, totalSlides)}
        onClick={props.onPreviousClick}
      />
      <Space fillAvailable />
      <Text color="tertiary">
        {inc(props.activeSlide)}/{totalSlides}
      </Text>
      <Heading size={3}>{props.title}</Heading>
      <Space fillAvailable />
      <ButtonIcon
        icon="arrowRight"
        variant="secondary"
        size={getValueByDevice(device, 'small', 'default')}
        isDisabled={gte(1, totalSlides)}
        onClick={props.onNextClick}
      />
    </HStack>
  );
}
