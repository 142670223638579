import {Heading, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {all, always, any, filter, length, pipe, propEq, values} from 'ramda';
import {isNonEmptyString, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

import {Carousel} from '../../components/Carousel/Carousel';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {Section} from '../../components/Section/Section';
import {SectionStateFlagProps} from '../../components/SectionStateFlag/SectionStateFlag';
import {Separator} from '../../components/Separator/Separator';
import {MechanicsDataBundle} from '../../hooks/useGetDataForMechanics';
import i18n from '../../i18n/i18n';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';
import {getValueWithUnit} from '../../utils/getValueWithUnit';
import {hasBrakeDiscInfo} from './utils/hasBrakeDiscInfo';
import {hasBrakeLiningWearInfo} from './utils/hasBrakeLiningWearInfo';
import {hasBrakePadInfo} from './utils/hasBrakePadInfo';

type MechanicsProps = Omit<MechanicsDataBundle, 'sectionConfig'>;
export function Mechanics({mechanicSlideshow, features, mechanics}: MechanicsProps) {
  if (!features || !mechanics) {
    return null;
  }
  const allFeatures = features.allFeatures;

  const {
    brakeSystemFeatures,
    electricalSystemFeatures,
    driveSystemFeatures,
    diagnosticsFeatures,
    chassisFeatures,
    bodyConditionFeatures,
  } = features.features;

  const isNotTested = all((f) => f.variant === 'neutral', allFeatures);
  const isDamaged = any((f) => f.variant === 'damaged', allFeatures);
  const countDamages: (object: object) => number = pipe(
    values,
    filter(propEq('damaged', 'variant')),
    length
  );

  return (
    <>
      <Section
        id="MECHANICS"
        icon="mechanics"
        heading={i18n.t('mechanicsHeader')}
        content={
          <VStack spacing={[3, 6, 6, 10]}>
            <Show when={isNotNilOrEmpty(brakeSystemFeatures)}>
              <Heading size={2}>{i18n.t('brakeSystem')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={brakeSystemFeatures}
              />
              <Separator />
            </Show>
            <Show when={hasBrakeDiscInfo(mechanics.brake)}>
              <Heading size={2}>{i18n.t('brakeDisc')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={[
                  {
                    title: i18n.t('valueLF'),
                    variant: mechanics.brake.brakeDiscLF.variant,
                    flagTexts: mechanics.brake.brakeDiscLF.flagTexts,
                    description: mechanics.brake.brakeDiscLF.description,
                  },
                  {
                    title: i18n.t('valueRF'),
                    variant: mechanics.brake.brakeDiscRF.variant,
                    flagTexts: mechanics.brake.brakeDiscRF.flagTexts,
                    description: mechanics.brake.brakeDiscRF.description,
                  },
                  {
                    title: i18n.t('valueLR'),
                    variant: mechanics.brake.brakeDiscLR.variant,
                    flagTexts: mechanics.brake.brakeDiscLR.flagTexts,
                    description: mechanics.brake.brakeDiscLR.description,
                  },
                  {
                    title: i18n.t('valueRR'),
                    variant: mechanics.brake.brakeDiscRR.variant,
                    flagTexts: mechanics.brake.brakeDiscRR.flagTexts,
                    description: mechanics.brake.brakeDiscRR.description,
                  },
                ]}
              />
            </Show>
            <Show when={hasBrakePadInfo(mechanics.brake.brakePad)}>
              <Separator />
              <Heading size={2}>{i18n.t('brakePad')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={[
                  {
                    title: i18n.t('valueLF'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakePad.LF.value,
                          unit: mechanics.brake.brakePad.LF.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                  {
                    title: i18n.t('valueRF'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakePad.RF.value,
                          unit: mechanics.brake.brakePad.RF.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                  {
                    title: i18n.t('valueLR'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakePad.LR.value,
                          unit: mechanics.brake.brakePad.LR.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                  {
                    title: i18n.t('valueRR'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakePad.RR.value,
                          unit: mechanics.brake.brakePad.RR.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                ]}
              />
            </Show>
            <Show when={hasBrakeLiningWearInfo(mechanics.brake.brakeLiningWear)}>
              <Separator />
              <Heading size={2}>{i18n.t('brakeLiningWear')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={[
                  {
                    title: i18n.t('valueLF'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakeLiningWear.LF.value,
                          unit: mechanics.brake.brakeLiningWear.LF.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                  {
                    title: i18n.t('valueLR'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakeLiningWear.LR.value,
                          unit: mechanics.brake.brakeLiningWear.LR.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                  {
                    title: i18n.t('valueRF'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakeLiningWear.RF.value,
                          unit: mechanics.brake.brakeLiningWear.RF.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                  {
                    title: i18n.t('valueRR'),
                    value:
                      getValueWithUnit({
                        auditValue: {
                          value: mechanics.brake.brakeLiningWear.RR.value,
                          unit: mechanics.brake.brakeLiningWear.RR.unit,
                        },
                      }) ?? EMPTY_PLACEHOLDER,
                    variant: 'neutral',
                  },
                ]}
              />
            </Show>
            <Show when={isNotNilOrEmpty(driveSystemFeatures)}>
              <Separator />
              <Heading size={2}>{i18n.t('driveSystem')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={driveSystemFeatures}
              />
            </Show>
            <Show when={isNotNilOrEmpty(chassisFeatures)}>
              <Separator />
              <Heading size={2}>{i18n.t('chassis')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={chassisFeatures}
              />
            </Show>
            <Show when={isNotNilOrEmpty(electricalSystemFeatures)}>
              <Separator />
              <Heading size={2}>{i18n.t('electricalSystem')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={electricalSystemFeatures}
              />
            </Show>
            <Show when={isNotNilOrEmpty(diagnosticsFeatures)}>
              <Separator />
              <Heading size={2}>{i18n.t('diagnostics')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={diagnosticsFeatures}
              />
            </Show>
            <Show when={isNotNilOrEmpty(bodyConditionFeatures)}>
              <Separator />
              <Heading size={2}>{i18n.t('bodyCondition')}</Heading>
              <ListFeatures
                columns={[1, 2, 2, 2]}
                spacing={[6, 6, 10, 10]}
                features={bodyConditionFeatures}
              />
            </Show>
            <Show when={isNotNilOrEmpty(mechanicSlideshow)}>
              <Separator />
              <Carousel isFilterOfDamageDisabled data={mechanicSlideshow} />
            </Show>
          </VStack>
        }
        flag={match<[boolean, boolean], Nullish | SectionStateFlagProps>([isNotTested, isDamaged])
          .with(
            [false, true],
            always({severity: 'damage', text: getRecordsTranslate(countDamages(allFeatures))})
          )
          .with([true, false], always(null))
          .otherwise(always({severity: 'good', text: i18n.t('sectionState.good')}))}
      />
    </>
  );
}

type Feature = {
  title: string | Nullish;
  isMandatory: boolean | Nullish;
};

const isFeatureVisible = (feature: Feature) =>
  isNonEmptyString(feature.title) ||
  (isNonEmptyString(feature.title) && isTrue(feature.isMandatory));
