import {z} from 'zod';

export const stringToJSONSchema = z.string().transform((value, ctx) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid JSON',
    });
    return z.never;
  }
});
