import {values, any} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Mechanics} from '../../../types/Mechanics';

type BreakPad = NonNullable<Mechanics>['brake']['brakePad'];

export function hasBrakePadInfo(brakePad: BreakPad) {
  return any((item: BreakPad[keyof BreakPad]) => {
    const isNotEmpty = isNotNilOrEmpty(item);
    if (isNotEmpty) {
      return values(item).some(isNotNilOrEmpty);
    }
    return false;
  })(values(brakePad));
}
