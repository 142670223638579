import {pick, values, any} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Mechanics} from '../../../types/Mechanics';

export function hasBrakeDiscInfo(
  mechanicsBrake: Pick<
    NonNullable<Mechanics>['brake'],
    'brakeDiscLF' | 'brakeDiscLR' | 'brakeDiscRF' | 'brakeDiscRR'
  >
) {
  const breakDiscProperties = pick(
    ['brakeDiscLF', 'brakeDiscLR', 'brakeDiscRF', 'brakeDiscRR'],
    mechanicsBrake
  );
  const breakDiscPropertiesValues = values(breakDiscProperties);

  return any<(typeof breakDiscPropertiesValues)[number]>((item) =>
    isNotNilOrEmpty(item.description)
  )(breakDiscPropertiesValues);
}
