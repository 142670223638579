import {AuditDataAssetsFilesBody, AuditDataVideoAssetsBody} from '../api/digitalCertificateApi';
import {SlideImage} from '../types/SlideImage';

export function mapAssetsToSlideImages(
  assets: (AuditDataAssetsFilesBody | AuditDataVideoAssetsBody)[]
): SlideImage[] {
  return assets
    .filter((asset): asset is AuditDataAssetsFilesBody => 'imageId' in asset)
    .map((asset) => ({
      id: `${asset.imageId}_${asset.url}`,
      url: asset.url,
      resizeUrl: asset.resizeUrl,
      fileName: asset.imageId,
      meta: asset.meta,
      type: 'image',
    }));
}
