import {Grid, GridItem, Image, makeImageUrl} from 'platform/foundation';

import {isNil} from 'ramda';

import {suffixTestId} from 'shared';

import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getPhotosByKeys} from '../../utils/getPhotosByKeys';
import {getVideosByKeys} from '../../utils/getVideosByKeys';

export function PhotoDocumentationPrint() {
  const {vehicleAudit} = useGetDigitalCertificateData();

  const photoDocumentationPhotos =
    getPhotosByKeys({
      vehicleAudit,
      parentUniqueKey: 'PHOTODOCUMENTATION',
    }) ?? [];
  const photoDocumentationVideos =
    getVideosByKeys({
      vehicleAudit,
      uniqueKey: 'VIDEOS',
      parentUniqueKey: 'PHOTODOCUMENTATION',
    }) ?? [];

  const photoDocumentation = [...photoDocumentationPhotos, ...photoDocumentationVideos];

  return (
    <SectionPrint
      data-testid="photoDocumentation"
      icon="photoDocumentation"
      heading={i18n.t('photosHeader')}
    >
      <Grid columns={2} spacing={6}>
        {photoDocumentation.map((slide) => {
          const imageUrl = slide.type === 'video' ? slide.coverImageUrl : slide.resizeUrl;

          if (isNil(imageUrl)) {
            return null;
          }

          return (
            <GridItem key={slide.id} span={1}>
              <Image
                fit="cover"
                width="100%"
                height={80}
                borderRadius="small"
                src={makeImageUrl(imageUrl, 88)}
                data-testid={suffixTestId('image', {'data-testid': slide.id})}
              />
            </GridItem>
          );
        })}
      </Grid>
    </SectionPrint>
  );
}
