import {AuditDataAssetsFilesBody, AuditDataVideoAssetsVideBody} from '../api/digitalCertificateApi';
import {SlideVideo} from '../types/SlideVideo';

export function mapAssetsToSlideVideos(
  assets: (AuditDataAssetsFilesBody | AuditDataVideoAssetsVideBody)[]
): SlideVideo[] {
  return assets
    .filter((asset): asset is AuditDataVideoAssetsVideBody => 'videoId' in asset)
    .map((asset) => ({
      ...asset,
      type: 'video',
    }));
}
