import {divide} from 'ramda';

import {Slide} from '../../../types/Slide';

const SLIDES_PER_PAGE = 8;

export function getSlidesByPage<TSlide extends Partial<Slide>>({
  page,
  slides,
  slidesPerPage = SLIDES_PER_PAGE,
}: {
  /**
   * Zero-based page index
   */
  page: number;
  slides: TSlide[];
  slidesPerPage?: number;
}) {
  const sliceStart = page * slidesPerPage;
  const sliceEnd = sliceStart + slidesPerPage;
  const currentSlides = slides.slice(sliceStart, sliceEnd);

  const pages = Math.ceil(divide(slides.length, slidesPerPage));

  return {currentSlides, pages, slidesPerPage};
}
