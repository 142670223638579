import {ComponentsProvider} from 'platform/components';

import {ReactNode} from 'react';

import {useThemeConfig} from '../../hooks/useThemeConfig';
import {i18n} from '../../i18n/i18n';

const CHAKRA_THEME_OVERRIDE = {
  styles: {
    global: () => ({
      body: {},
    }),
  },
};

interface ComponentsWithThemeProviderProps {
  children: ReactNode;
}

export function ComponentsWithThemeProvider(props: ComponentsWithThemeProviderProps) {
  const {theme} = useThemeConfig();

  return (
    <ComponentsProvider
      theme={theme}
      chakraThemeOverride={CHAKRA_THEME_OVERRIDE}
      t={i18n.t}
      language={i18n.language}
    >
      {props.children}
    </ComponentsProvider>
  );
}
