import {flatten} from 'ramda';

import {Nullish} from 'shared';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {composeVideoAssetsByRelatedActions} from './composeVideoAssetsByRelatedActions';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';
import {mapAssetsToSlideVideos} from './mapAssetsToSlideVideos';

type VideosByKeysParams = {
  parentUniqueKey: string;
  vehicleAudit: Pick<VehicleAudit, 'auditStructure' | 'audit'> | Nullish;
  uniqueKey?: string | string[] | Nullish;
};

export function getVideosByKeys(params: VideosByKeysParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.auditStructure.categories) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: params.parentUniqueKey,
    uniqueKey: params.uniqueKey,
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length) {
    return null;
  }

  const videoAssets = composeVideoAssetsByRelatedActions(
    relatedActions,
    params.vehicleAudit.audit.categories
  );

  if (!videoAssets?.length) {
    return null;
  }

  return mapAssetsToSlideVideos(flatten(videoAssets.map((asset) => asset.videos)));
}
