import {getValueByDevice, Hide, useDevice, Image, makeImageUrl} from 'platform/foundation';
import {css} from 'styled-components';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

export interface LightboxItemProps extends TestIdProps {
  imageUrl: string | Nullish;
  isActive: boolean;
  title?: string;
  onClick: () => void;
}

export function Slide(props: LightboxItemProps) {
  const device = useDevice();

  return (
    <Hide when={!props.isActive}>
      <div
        css={css`
          width: 100%;
          height: 100%;
          position: ${props.isActive ? 'relative' : 'absolute'};
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        `}
        onClick={props.onClick}
      >
        <Image
          isLazy
          fit="cover"
          position="center"
          ratio="4 / 3"
          width="100%"
          height="100%"
          src={makeImageUrl(props.imageUrl, getValueByDevice(device, 100, 160, 200, 200))}
          alt={props.title}
          hasSpinner
          data-testid={suffixTestId('slideImage', props)}
        />
      </div>
    </Hide>
  );
}
